<template>
    <v-container fluid class="home ma-0 pa-0">
        <v-container class="pa-8" v-if="userRole.toUpperCase().includes('BLACKLIST')">
            <div>
                <v-row
                    justify="center"
                    class="null-item"
                >
                <v-col class="d-flex justify-center pb-0" cols="10">
                    <v-img
                    class="image"
                    :src="require('../../assets/bus-homepage.svg')"
                    ></v-img>
                </v-col>
                <v-col class="d-flex justify-center py-0 mt-4" cols="12">
                    <h2 class="text-center">{{ $t("SuspendWarning") }}</h2>
                </v-col>
                </v-row>
            </div>
        </v-container>
        <v-row class="ma-0" v-else>            
            <v-col class="card-content" cols="12">
                <v-card 
                    v-for="item in $partnerACL.getSubMenu()"
                    :key="item.title"
                    :to="item.path"
                    outlined class="ma-4 rounded-card">
                    <v-row class="pl-5 pt-1 pb-1" align="center">
                        <v-col v-if="item.icon" class="mr-0 pr-0 d-flex justify-center" cols="3">
                            <v-img
                                max-width="77"
                                :src="item.icon"
                            >
                            </v-img>
                        </v-col>
                        <v-col cols="9" class="ml-0 pl-0">
                            <v-card-title>{{ $t(item.title + 'PAYG') }}</v-card-title>
                            <v-card-subtitle class="ml-1">{{
                                $t('Desc' + item.title + 'PAYG')
                            }}</v-card-subtitle>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "PayAsUGo",
    components: {},
    data() {
        return {

        }
    },
  computed: {
    userRole() {
      return this.$store.state.auth.user_role;
    }
  },
    mounted() {
        
    },
}
</script>

<style scoped>
.rounded-card {
  border-radius: 15px !important;
}
</style>